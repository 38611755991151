import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./portfolio.css";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-bootstrap";
import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import AlertOverlay from "../../components/AlertOverlay/AlertOverlay";
const questions = [
  "ques10checkbox1",
  "ques10checkbox2",
  "ques10checkbox3",
  "ques10checkbox4",
  "ques10checkbox5",
  "ques10checkbox6",
];
const isOneofQues10 = (name) => {
  if (questions.indexOf(name) >= 0) {
    return true;
  } else {
    return false;
  }
};

const isFirstRow = (name) => {
  if (questions.indexOf(name) >= 0 && questions.indexOf(name) <= 2) {
    return true;
  } else {
    return false;
  }
};

const isAtleastOneTrue = (values, changedField, changedValue) => {
  const indexOfChange = questions.indexOf(changedField);
  if (isFirstRow(changedField)) {
    values[indexOfChange] = changedValue;
  } else {
    values[indexOfChange - 3] = changedValue;
  }
  for (let i = 0; i < values.length; i++) {
    if (values[i]) {
      return true;
    }
  }
  return false;
};

const PortfolioPreferences = forwardRef((props, ref) => {
  const {
    handleActiveTab,
    formikValues,
    setFormValues,
    getFormValues,
    personCountry,
  } = props;
  const genError = () => {
    return (
      <FormattedMessage
        id="error.gen"
        defaultMessage="This field is required"
      />
    );
  };
  const alertMessage = (
    <FormattedMessage
      id="error.ques10cbox"
      defaultMessage="Minimum one selection required"
    />
  );
  const currentTabName = "Portfolio Preferences";
  const currentTabVals = formikValues[currentTabName];
  const MyPortfolioPreferences = JSON.parse(
    sessionStorage.getItem("MyPortfolioPreferences")
  );
  const [alertError, setAlertError] = useState("");
  const { isClient } = props;
  useEffect(() => {
    setTimeout(() => setAlertError(""), 5000);
  }, [alertError]);
  const scrollToTop = () => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  };
  useEffect(() => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ques8:
        personCountry === "Kuwait"
          ? true
          : MyPortfolioPreferences && MyPortfolioPreferences.ques8
          ? MyPortfolioPreferences.ques8
          : currentTabVals.ques8,
      ques9:
        personCountry === "Kuwait"
          ? true
          : MyPortfolioPreferences && MyPortfolioPreferences.ques9
          ? MyPortfolioPreferences.ques9
          : currentTabVals.ques9,
      ques10checkbox1:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox1
          ? MyPortfolioPreferences.ques10checkbox1
          : currentTabVals.ques10checkbox1,
      ques10checkbox2:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox2
          ? MyPortfolioPreferences.ques10checkbox2
          : currentTabVals.ques10checkbox2,
      ques10checkbox3:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox3
          ? MyPortfolioPreferences.ques10checkbox3
          : currentTabVals.ques10checkbox3,
      ques10checkbox4:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox4
          ? MyPortfolioPreferences.ques10checkbox4
          : currentTabVals.ques10checkbox4,
      ques10checkbox5:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox5
          ? MyPortfolioPreferences.ques10checkbox5
          : currentTabVals.ques10checkbox5,
      ques10checkbox6:
        MyPortfolioPreferences && MyPortfolioPreferences.ques10checkbox6
          ? MyPortfolioPreferences.ques10checkbox6
          : currentTabVals.ques10checkbox6,
    },
    initialTouched: {
      ques8: true,
      ques9: true,
      ques10checkbox1: true,
      ques10checkbox2: true,
      ques10checkbox3: true,
      ques10checkbox4: true,
      ques10checkbox5: true,
      ques10checkbox6: true,
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      sessionStorage.setItem("MyPortfolioPreferences", JSON.stringify(values));
      setFormValues(values);
      handleActiveTab("Investment Behaviour");
    },
    validateOnMount: true,
  });
  useImperativeHandle(ref, () => ({
    validatePortfoliopreferences: () => {
      return {
        ...formik,
      };
    },
    submitPortfoliopreferences: () => {
      return formik.submitForm();
    },
  }));
  const myPortfolio = getFormValues("My Portfolio");

  const setFormikValue = (e) => {
    if (isOneofQues10(e.target.name)) {
      if (!e.target.checked) {
        if (isFirstRow(e.target.name)) {
          if (
            isAtleastOneTrue(
              [
                formik.values.ques10checkbox1,
                formik.values.ques10checkbox2,
                formik.values.ques10checkbox3,
              ],
              e.target.name,
              e.target.checked
            )
          ) {
            formik.setFieldValue(e.target.name, e.target.checked);
          } else {
            setAlertError(e.target.name);
          }
        } else {
          if (
            isAtleastOneTrue(
              [
                formik.values.ques10checkbox4,
                formik.values.ques10checkbox5,
                formik.values.ques10checkbox6,
              ],
              e.target.name,
              e.target.checked
            )
          ) {
            formik.setFieldValue(e.target.name, e.target.checked);
          } else {
            setAlertError(e.target.name);
          }
        }
      } else {
        formik.setFieldValue(e.target.name, e.target.checked);
      }
    } else {
      if (e.target.type === "checkbox") {
        formik.setFieldValue(e.target.name, e.target.checked);
      } else {
        formik.setFieldValue(e.target.name, e.target.value);
      }
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <section>
        <h5 className="container-heading">
          <FormattedMessage
            id="header.portfolio"
            defaultMessage="header.portfolio"
          />
        </h5>

        <div className="checkbox-item checkbox-container">
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col mar-button">
              <label className="checkbox-wrapper ">
                <input
                  type="checkbox"
                  name="ques8"
                  defaultChecked={formik.values.ques8}
                  onChange={setFormikValue}
                  disabled={personCountry === "Kuwait"}
                />
                <span className="checkbox-btn"></span>
                <h6>
                  <FormattedMessage id="q.eight" defaultMessage="q.eight" />
                </h6>
                <p className="q-text">
                  <FormattedMessage
                    id="q.eight.subtext"
                    defaultMessage="q.eight.subtext"
                  />
                </p>
              </label>
            </Col>
          </Row>
          {/* <Checkbox /> */}
          {/* <input type='checkbox' /> */}
          {/* <div className='checkbox-label'>
                    <h6>
                        <FormattedMessage id="q.eight" defaultMessage="q.eight" />
                    </h6>
                    <p className="q-text">
                        <FormattedMessage id="q.eight.subtext" defaultMessage="q.eight.subtext" />
          </p>
                </div> */}
        </div>
        {/* {formik.touched.ques8 && formik.errors.ques8 ? (
          <div className='validation-error'>{formik.errors.ques8}</div>
        ) : null} */}
        <div className="checkbox-item">
          {/* <Checkbox/> */}
          {/* <input type='checkbox' /> */}
          {/* <div className='checkbox-label'>
                    <h6>
                        <FormattedMessage id="q.nine" defaultMessage="q.nine" />
                    </h6>
                    <p className="q-text">
                        <FormattedMessage id="q.nine.subtext" defaultMessage="q.nine.subtext" />
          </p>
                </div> */}
          <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col mar-button">
              <label className="checkbox-wrapper ">
                <input
                  type="checkbox"
                  name="ques9"
                  defaultChecked={formik.values.ques9}
                  onChange={setFormikValue}
                  disabled={personCountry === "Kuwait"}
                />
                <span className="checkbox-btn"></span>
                <h6>
                  <FormattedMessage id="q.nine" defaultMessage="q.nine" />
                </h6>
                <p className="q-text">
                  <FormattedMessage
                    id="q.nine.subtext"
                    defaultMessage="q.nine.subtext"
                  />
                </p>
              </label>
            </Col>
          </Row>
        </div>
        {/* {formik.touched.ques9 && formik.errors.ques9 ? (
          <div className='validation-error'>{formik.errors.ques9}</div>
        ) : null} */}
        {isClient ? (
          <div></div>
        ) : (
          props.showTenthQuestion && (
            <div className="checkbox-item alignment">
              {/* <Checkbox/> */}
              {/* <Row className="ans-row">
            <Col xs={12} sm={"auto"} className="ans-col mar-button">
              <label className="checkbox-wrapper ">
                <input type="checkbox" disabled={!props.showTenthQuestion} />
                <span className="checkbox-btn"></span>
              </label>
            </Col>
          </Row> */}
              <div className="arabiccheckbox">
                <h6>
                  <FormattedMessage id="q.ten" defaultMessage="q.ten" />
                </h6>
                <p className="pleaseApply">
                  <FormattedMessage
                    id="q.ten.header"
                    defaultMessage="q.ten.header"
                  />
                </p>
                <div className="checkboxContainer">
                  <h6>
                    <FormattedMessage
                      id="q.ten.checkbox.header1"
                      defaultMessage="q.ten.checkbox.header1"
                    />{" "}
                    <br />
                    <FormattedMessage
                      id="q.ten.checkbox.header2"
                      defaultMessage="q.ten.checkbox.header2"
                    />
                  </h6>
                  <ul>
                    <li>
                      <Row className="ans-row firstcheckbox">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              disabled={!props.showTenthQuestion}
                              name="ques10checkbox1"
                              checked={formik.values.ques10checkbox1}
                              onChange={setFormikValue}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox1"
                          defaultMessage="q.ten.checkbox1"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox1" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <Row className="ans-row secondcheckbox">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              disabled={!props.showTenthQuestion}
                              name="ques10checkbox2"
                              checked={formik.values.ques10checkbox2}
                              onChange={setFormikValue}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox2"
                          defaultMessage="q.ten.checkbox2"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox2" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <Row className="ans-row thirdcheckbox">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              disabled={!props.showTenthQuestion}
                              name="ques10checkbox3"
                              checked={formik.values.ques10checkbox3}
                              onChange={setFormikValue}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox3"
                          defaultMessage="q.ten.checkbox3"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox3" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
                <div className="checkboxContainer2">
                  <h6>
                    <FormattedMessage
                      id="q.ten.checkbox.header3"
                      defaultMessage="q.ten.checkbox.header3"
                    />{" "}
                    <br />
                    <FormattedMessage
                      id="q.ten.checkbox.header4"
                      defaultMessage="q.ten.checkbox.header4"
                    />
                  </h6>
                  <ul>
                    <li>
                      <Row className="ans-row">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              disabled={!props.showTenthQuestion}
                              name="ques10checkbox4"
                              checked={formik.values.ques10checkbox4}
                              onChange={setFormikValue}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox4"
                          defaultMessage="q.ten.checkbox4"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox4" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <Row className="ans-row marginleftn45">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              name="ques10checkbox5"
                              checked={formik.values.ques10checkbox5}
                              onChange={setFormikValue}
                              disabled={!props.showTenthQuestion}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox5"
                          defaultMessage="q.ten.checkbox5"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox5" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <Row className="ans-row">
                        <Col xs={"auto"} className="ans-col mar-button">
                          <label className="checkbox-wrapper ">
                            <input
                              type="checkbox"
                              onChange={setFormikValue}
                              name="ques10checkbox6"
                              checked={formik.values.ques10checkbox6}
                              disabled={!props.showTenthQuestion}
                            />
                            <span className="checkbox-btn"></span>
                          </label>
                        </Col>

                        <FormattedMessage
                          id="q.ten.checkbox6"
                          defaultMessage="q.ten.checkbox6"
                        />
                      </Row>
                      {alertError && alertError === "ques10checkbox6" ? (
                        <div className="validation-error-cbox">
                          {alertMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
        )}
        <ButtonContainer
          backAction={() => {
            setFormValues(formik.values);
            handleActiveTab("My Portfolio");
            scrollToTop();
          }}
          nextLabel="portfolio.nextbutton"
        />
      </section>
    </form>
  );
});
export default PortfolioPreferences;
